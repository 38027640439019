import React from 'react';
import tw, { styled } from 'twin.macro';

export const DistanceTable = styled.div`
  ${tw`pt-4`}
`;
export const DistanceRow = styled.div`
  ${tw`flex justify-between px-2 py-1`}
`;

const Distances = () => {
  const distances = [
    { city: 'Denver', miles: '180' },
    { city: 'Grand Junction', miles: '115' },
    { city: 'Glenwood Springs', miles: '29' },
    { city: 'Aspen', miles: '24' },
    { city: 'Basalt', miles: '5.6' },
  ];

  return (
    <DistanceTable>
      {distances.map((loc, k) => (
        <DistanceRow key={loc.city}>
          <div>{loc.city}</div>
          <div>{loc.miles} miles</div>
        </DistanceRow>
      ))}
    </DistanceTable>
  );
};

export default Distances;
