import React from 'react';

import PageContainer from '../components/page-container';
import SEO from '../components/seo';
import Distances from '../components/distances';
import {
  TitleBar,
  PageTitle,
  SectionTitle,
  PageSection,
  FlexContainer,
  FlexItem,
  TextContainer,
} from '../utils/elements.stc';

const DirectionsPage = () => {
  const seo = {
    title: 'Directions',
    keywords: [
      'basalt, CO cabins',
      'taylor creek cabins',
      'fly fishing cabins',
      'basalt fly fishing',
    ],
    descriptions: 'Directions page to Taylor Creek Cabins',
  };

  return (
    <PageContainer>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <PageTitle tw="font-light md:font-light">{seo.title}</PageTitle>
      </TitleBar>
      <PageSection>
        <FlexContainer pb0>
          <FlexItem pr w13>
            <p>
              LOCATED 5.6 MILES FROM BASALT, CO AT THE CONFLUENCE OF TAYLOR
              CREEK AND THE FRYING PAN RIVER.
            </p>
            <Distances />
          </FlexItem>
          <FlexItem>
            <div>
              <iframe
                title="Dynamic Google Map"
                width="375"
                height="375"
                frameBorder="0"
                style={{ border: 0 }}
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJKbxXpM5KQIcR6V3AXeJv1UE&zoom=10&key=AIzaSyBfNA4R0oJy_6HeSIK8wpOLuGTam88CSDA"
                allowFullScreen
              ></iframe>
            </div>
          </FlexItem>
        </FlexContainer>
      </PageSection>
      <PageSection bglight>
        <FlexItem>
          <TextContainer wfull>
            <SectionTitle>From Denver</SectionTitle>
            <p>
              Take I70 W and use Exit 116 (Aspen/ Glenwood Springs.) to get onto
              Highway 82. Highway 82 runs through the Roaring Fork Valley and is
              the main thoroughfare to Carbondale, Basalt and Aspen. Basalt is
              20 miles from Glenwood Springs and is a left turn off Highway 82.
              Aspen is 40 miles from Glenwood Springs. All turnoffs to Basalt
              lead to the main street called Midland Avenue. Proceeding along
              Midland Avenue you will see "Alpine Bank" on the right-hand side.
              It used to be the old Train station when the railway line ran up
              the Frying Pan Road.
            </p>
          </TextContainer>
          <TextContainer wfull>
            <SectionTitle>From Basalt</SectionTitle>
            <p>
              Stay on Midland Ave as this actually becomes the Frying Pan Road.
              At the start of the Frying Pan Road is a tiny Barbers shop on your
              left. You will also see a sign stating "No gasoline sales past
              this point". Once on the Frying Pan Road, watch out for the mile
              markers. They are on the right hand side of the road. They are
              small rectangle numbers on yellow poles. Once you spot mile marker
              5 you are very close. You will also notice a log fence on the
              right-hand side of the road. The end of this fence is the start of
              our private access water. The lst street on the left hand side of
              the road is Knight Road. The turnoff to the cabins is a further
              3/4 th mile down the road on the left on the bend in the road.
              Look for mail boxes on the right hand side of the road. They are
              directly opposite the entrance to Taylor Creek Cabins.
            </p>
          </TextContainer>
        </FlexItem>
      </PageSection>
    </PageContainer>
  );
};

export default DirectionsPage;
