import tw, {styled} from 'twin.macro';

export const TitleBar = styled.div`
  ${tw`w-full px-4 pt-8 mb-4 md:mb-8 md:px-16 bg-primary-100 shadow-md`}
`;
export const PageTitle = styled.h1`
  ${tw`font-heading text-2xl font-normal text-gray-800 text-center uppercase pb-4`}
  ${tw`md:text-left md:font-normal md:text-3xl md:pb-4`}
`;

export const FlexContainer = styled.div`
  ${tw`flex flex-row flex-wrap justify-between md:flex-no-wrap`}
  ${tw`md:pb-12`}
  ${({ pb0 }) => pb0 && tw`md:pb-0`}
  ${({ bglight }) => bglight && tw`bg-primary-100`}
  ${({ center }) => center && tw`md:justify-center`}
  ${({ around }) => around && tw`md:justify-around`}
  ${({ start }) => start && tw`md:justify-start`}
  ${({ reverse }) => reverse && tw`flex-wrap-reverse`}
`
export const FlexItem = styled.div`
  ${tw`pb-4`}
  ${({ pl }) => pl && tw`pl-8`}
  ${({ pr }) => pr && tw`pr-8`}
  ${({ w23 }) => w23 && tw`md:w-2/3`}
  ${({ w12 }) => w12 && tw`md:w-1/2`}
  ${({ w13 }) => w13 && tw`md:w-1/3`}
`
export const TextContainer = styled.div`
  ${tw`w-full mb-12 md:w-1/2 text-lg`}
  ${({ w13 }) => w13 && tw`md:w-1/3`}
  ${({ w23 }) => w23 && tw`md:w-2/3`}
  ${({ wfull }) => wfull && tw`md:w-full`}
  ${({ base }) => base && tw`md:text-base`}
  ${({ xl }) => xl && tw`md:text-xl`}
  ${({ xl2 }) => xl2 && tw`md:text-2xl`}
`;
export const PageSection = styled.section`
  ${tw`px-4 py-4 md:px-16 md:py-8`}
  ${({ bglight }) => bglight && tw`bg-primary-100`}
  ${({ pt0 }) => pt0 && tw`md:pb-0`}
  ${({ pb0 }) => pb0 && tw`md:pb-0`}
`;
export const SectionTitle = styled.h2`
  ${tw`font-heading font-medium text-gray-900 text-xl text-center uppercase pb-4`}
  ${tw`md:text-left`}
  ${({ center }) => center && tw`md:text-center`}
  ${({ medium }) => medium && tw`md:font-medium`}
  ${({ normal }) => normal && tw`md:font-normal`}
`;
export const Button = styled.button`
  ${tw`font-heading p-2 text-sm font-light text-center text-txt-200 uppercase bg-primary-600 border rounded border-gray-200`}
  background: linear-gradient(#163e3e, #163e3edb);
  &:hover {
    opacity: 0.9;
  }
`;
export const Center = styled.div`
  ${tw`text-center`}
`;
export const ImageContainer = styled.div`
  ${tw` mb-8`}
  ${({ mt }) => mt && tw`mt-4 md:mt-8`}
  ${({ mb }) => mb && tw`mb-4 md:mb-8`}
`;
export const StaticMapContainer = styled.div`
  ${tw`w-full`}
  max-width: 350px;
  max-height: 300px;
`;
